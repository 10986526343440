// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+lyqF9\\+o{align-items:flex-start;display:flex}.\\+lyqF9\\+o>:not(:last-child){margin-right:var(--spacing-lg)}@media(max-width:667.98px){.\\+lyqF9\\+o>:not(:last-child){margin-right:var(--spacing-md)}}.dEO1u8yu{border-radius:var(--border-radius);font-size:2rem;height:8rem;overflow:hidden;width:8rem}@media(max-width:667.98px){.dEO1u8yu{font-size:1rem;height:5rem;width:5rem}}._3eowVMM2{color:var(--color-bg-invert);font-size:1.5rem;font-weight:700;line-height:1.46;margin-bottom:var(--spacing-sm);word-wrap:anywhere}@media(max-width:667.98px){._3eowVMM2{font-size:1.25rem}}.ARzn2MWf{font-size:1rem;font-weight:500;line-height:1.62;word-wrap:anywhere}@media(max-width:667.98px){.ARzn2MWf{font-size:.88rem}}.P52EmspI{font-size:1rem;line-height:1.62;padding-top:var(--spacing-lg);word-wrap:break-word;-webkit-hyphens:auto;hyphens:auto}@media(max-width:667.98px){.P52EmspI{font-size:.88rem;padding-top:var(--spacing-md)}}.Xkbfymup,.V6Hkhwca{margin-top:var(--spacing-lg)}@media(max-width:667.98px){.Xkbfymup,.V6Hkhwca{margin-top:var(--spacing-md)}}.o4-192uO{display:none;padding-top:var(--spacing-sm);transition:.3s linear;transition-property:max-height}@media(max-width:667.98px){.o4-192uO{padding-top:var(--spacing-s)}}.o4-192uO>:not(:last-child){margin-bottom:var(--spacing-sm)}@media(max-width:667.98px){.o4-192uO>:not(:last-child){margin-bottom:var(--spacing-s)}}.Xkbfymup{align-items:center;color:var(--color-bg-invert);display:flex;font-size:1.25rem;font-weight:600;letter-spacing:.15px;line-height:1.5}@media(max-width:667.98px){.Xkbfymup{font-size:1rem}}.RbG1ZU7u{display:block}.ksL1m89z{--size:1.2em;margin-left:.3em;transition:.1s linear;transition-property:transform}.jsBMHYV9{transform:rotate(90deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"top": "+lyqF9+o",
	"photo": "dEO1u8yu",
	"name": "_3eowVMM2",
	"job": "ARzn2MWf",
	"bio": "P52EmspI",
	"sessionsHeading": "Xkbfymup",
	"spinner": "V6Hkhwca",
	"sessions": "o4-192uO",
	"sessionsOpen": "RbG1ZU7u",
	"chevron": "ksL1m89z",
	"chevronDown": "jsBMHYV9"
};
module.exports = ___CSS_LOADER_EXPORT___;
