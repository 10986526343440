import Vue from "vue"
import { mapGetters, mapActions } from "vuex"
import { isSessionLive } from "@/utils/sessions"
import type { Session } from "@/types/api/program"

export default Vue.extend({
  props: {
    session: { type: Object as VuePropType<Session>, required: true },
  },

  computed: {
    ...mapGetters("user", ["isSessionFavorite"]),

    isFavorite: {
      get (): boolean { return this.isSessionFavorite(this.session.alias) },
      set (isFavorite: boolean) {
        const method = isFavorite
          ? "addFavoriteSession"
          : "removeFavoriteSession"
        this[method](this.session)
      },
    },

    trackName (): string {
      return this.$fmt.localize(this.session.track.hall.title)
    },

    trackDesc (): string {
      return this.$fmt.localize(this.session.track.description)
    },

    isLive (): boolean {
      const { session } = this
      return Boolean(session.track.liveData?.embedCode) &&
        isSessionLive(this.session)
    },
    liveLink (): string {
      return this.localePath(`/live/${this.session.track.hall.alias}`)
    },
    isLiveVisible (): boolean {
      return this.$route.path !== this.liveLink
    },
  },

  methods: {
    ...mapActions("user", ["addFavoriteSession", "removeFavoriteSession"]),
  },
})
