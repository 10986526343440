
import sessionMixin from "@/mixins/session"

export default {
  mixins: [sessionMixin],

  methods: {
    toggle () {
      if (this.$auth.loggedIn) this.isFavorite = !this.isFavorite
      else this.$nuxt.$emit("open-login")
    },
  },
}
