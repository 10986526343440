// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NxMeZrWh{background:var(--color-bg);border:1px solid transparent;border-radius:var(--border-radius);box-shadow:0 .25rem 3rem rgba(var(--color-shadow-rgb),.12);display:flex;flex-direction:column;padding:var(--spacing-md);transition:.1s linear;transition-property:border}@media(hover:hover){.NxMeZrWh:hover{border-color:var(--color-gray-3)}}@media(hover:none){.NxMeZrWh:active{border-color:var(--color-gray-3)}}.NxMeZrWh:not(:last-child){margin-bottom:var(--spacing-sm)}@media(max-width:667.98px){.NxMeZrWh{padding:calc(var(--spacing-default)*5)}}.aPnlZllI{justify-content:space-between;margin-bottom:var(--spacing-s)}.aPnlZllI,.Ptx-HB\\+h{align-items:center;display:flex}.Ptx-HB\\+h{overflow:hidden}.zDLbBpSI{align-items:center;display:flex;margin-left:var(--spacing-md)}.zDLbBpSI>:not(:last-child){margin-right:var(--spacing-sm)}@media(max-width:667.98px){.zDLbBpSI>:not(:last-child){margin-right:var(--spacing-xs)}}.g579hfFl{margin-right:var(--spacing-lg)}@media(max-width:667.98px){.g579hfFl{margin-right:var(--spacing-sm)}}.c1V-miLY{color:var(--color-primary-on-bg);font-size:1rem;font-weight:500;line-height:1.6;margin-right:var(--spacing-md)}@media(max-width:667.98px){.c1V-miLY{font-size:.69rem;line-height:2.3;margin-right:var(--spacing-sm)}}.zAuf0kP-,.c1V-miLY{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.g579hfFl,.zAuf0kP-{color:var(--color-gray-4);font-size:.81rem;font-weight:500;line-height:1.39}@media(max-width:667.98px){.g579hfFl,.zAuf0kP-{font-size:.69rem;line-height:1.63}}.RuUx-tKP{--icon-size:1.5rem}.I4\\+ZD-1q{color:var(--color-bg-invert);font-size:1.5rem;font-weight:700;line-height:1.46;word-wrap:break-word}@media(max-width:667.98px){.I4\\+ZD-1q{font-size:1rem;font-weight:600;letter-spacing:.15px}}.h5Ag\\+AzN.NxMeZrWh{padding:var(--spacing-sm)}.h5Ag\\+AzN .I4\\+ZD-1q{font-size:.88rem;font-weight:600;line-height:1.57}.h5Ag\\+AzN .c1V-miLY{font-size:.81rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "h5Ag+AzN",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "NxMeZrWh",
	"head": "aPnlZllI",
	"headMeta": "Ptx-HB+h",
	"headAside": "zDLbBpSI",
	"time": "g579hfFl",
	"trackName": "c1V-miLY",
	"trackDesc": "zAuf0kP-",
	"btn": "RuUx-tKP",
	"title": "I4+ZD-1q"
};
module.exports = ___CSS_LOADER_EXPORT___;
