
import sessionMixin from "@/mixins/session"

export default sessionMixin.extend({
  props: {
    isSm: { type: Boolean, default: false },
  },

  methods: {
    openModal () {
      this.$emit("open-modal")
      this.$nuxt.$emit("open-session", this.session)
    },
  },
})
