
import Vue from "vue"
import { mapGetters } from "vuex"
import { getRange } from "@/utils/date"

export default Vue.extend({
  props: {
    start: { type: Number, required: true },
    finish: { type: Number, default: 0 },
    isLocal: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters("app", ["timezone"]),

    dateTime (): string {
      const { formatISOZoned } = this
      let dateTime = formatISOZoned(this.start)
      const { finish } = this
      if (finish) dateTime += `/${formatISOZoned(finish)}`
      return dateTime
    },

    rangeString (): string {
      const { start, finish, isLocal } = this
      return finish
        ? getRange(
          start, finish, this.$i18n.locale, isLocal ? "" : this.timezone,
        )
        : this.$fmt.date(start, "HH:mm", { local: isLocal })
    },
  },

  methods: {
    formatISOZoned (ts: number): string {
      return this.$fmt.date(
        ts, "yyyy-MM-dd'T'HH:mm:ssxxx", { local: this.isLocal },
      )
    },
  },
})
